import React from 'react';
import { Text } from '@amzn/storm-ui';
import { Audience } from '../../../models';
import styled from 'styled-components';

type ActionRendererProps = {
  /**
   * segment data for this row.
   */
  data: Audience;
  /**
   * Whether to use daily impressions or daily reach
   */
  useImpressions: boolean;
};

const ForecastContainer = styled(Text)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const shortForm = (num: number): string => {
  if (num >= 1_000_000)
    return num % 1_000_000 === 0
      ? `${num / 1_000_000}M`
      : `${(num / 1_000_000).toFixed(1)}M`;

  if (num >= 1_000)
    return num % 1_000 === 0
      ? `${num / 1_000}K`
      : `${(num / 1_000).toFixed(1)}K`;

  return num.toString();
};

const getForecast = (audience: Audience, useImpressions: boolean): string => {
  const bounds = useImpressions
    ? audience.forecasts?.inventoryForecasts?.all?.dailyImpressions
    : audience.forecasts?.inventoryForecasts?.all?.dailyReach;

  const upperBound = bounds?.upperBoundExclusive;
  const lowerBound = bounds?.lowerBoundInclusive;

  if (!upperBound && lowerBound) return `Over ${shortForm(lowerBound)}`;
  if (upperBound && (!lowerBound || lowerBound === 1))
    return `Less than ${shortForm(upperBound)}`;
  if (lowerBound && upperBound)
    return `${shortForm(lowerBound)} - ${shortForm(upperBound)}`;

  return '-';
};

export const ForecastRenderer = ({
  data,
  useImpressions,
}: ActionRendererProps) => {
  if (!data) return null;

  return (
    <ForecastContainer>
      <Text>{getForecast(data, useImpressions)}</Text>
    </ForecastContainer>
  );
};
