import { ColDef } from '@ag-grid-community/core';
import { ActionRenderer } from '../renderers/ActionRenderer';
import { AudienceNameRenderer } from '../renderers/AudienceNameRenderer';
import { FeeRenderer } from '../renderers/FeeRenderer';
import { CategoryRenderer } from '../renderers/CategoryRenderer';
import { ApiData } from 'models';
import { ForecastRenderer } from '../renderers/ForecastRenderer';
export const ACTION_COL_ID = 'action';
export const NAME_COL_ID = 'audienceName';
export const CATEGORY_COL_ID = 'category';
export const FEE_COL_ID = 'fee';
export const DAILY_IMPRESSIONS_COL_ID = 'dailyImpressions';
export const DAILY_REACH_COL_ID = 'dailyReach';
export const FILTER_DROPDOWN_VIRT_COL_ID = 'FilterCategoryDropdown';
export const TEXT_SEARCH_VIRT_COL_ID = 'TextSearchCol';

type ColumnDefProps = {
  apiData: ApiData;
  /**
   * Whether to show the "legacy" logic version of the picker, or the "include/exclude" logic version of the picker.
   */
  useLegacy?: boolean;
  /**
   * List of feeSupplyType(s) that dictate which fee to use from a given audience segment
   */
  feeSupplyType?: string[];
  /**
   * the bulk edit command if using the BulkEditTargetingGroupComponent, such as include, exclude, or remove.
   */
  bulkEditMutationType?: string;
  disableRowsCallback?: (data: any) => string;
  isInclusionDisabled?: boolean;
  inclusionDisabledReasonCode?: string;
  showForecast?: boolean;
};

/**
 * UDC-format column definitions for the tableComponent.
 */
export const getColumnDefs = (
  headerLabels: any,
  {
    apiData,
    useLegacy,
    feeSupplyType,
    bulkEditMutationType,
    disableRowsCallback,
    isInclusionDisabled,
    inclusionDisabledReasonCode,
    showForecast,
  }: ColumnDefProps
) =>
  [
    {
      colId: TEXT_SEARCH_VIRT_COL_ID, // virtual column for server side filtering
      filter: 'agTextColumnFilter',
      hide: true, // do not show in table
      lockVisible: true, // disable visibility changing via menu
    },
    {
      colId: FILTER_DROPDOWN_VIRT_COL_ID, // virtual column for server side filtering
      filter: 'audienceCategoryFilter',
      hide: true, // do not show in table
      lockVisible: true, // disable visibility changing via menu
    },
    {
      colId: ACTION_COL_ID,
      headerName: '',
      field: ACTION_COL_ID,
      sortable: false,
      width: useLegacy ? 60 : 40,
      valueGetter: ({ data }: { data: any }) => {
        if (!data) return undefined;
        return data;
      },
      cellRendererFramework: ActionRenderer,
      cellRendererParams: {
        useLegacy,
        disableRowsCallback,
        feeSupplyType,
        bulkEditMutationType,
        isInclusionDisabled,
        inclusionDisabledReasonCode,
      },
    },
    {
      colId: CATEGORY_COL_ID,
      headerName: headerLabels.category,
      field: CATEGORY_COL_ID,
      sortable: false,
      width: 70,
      valueGetter: ({ data }: { data: any }) => {
        if (!data) return undefined;
        return data;
      },
      cellRendererFramework: CategoryRenderer,
    },
    {
      colId: NAME_COL_ID,
      headerName: headerLabels.name,
      field: NAME_COL_ID,
      sortable: false,
      width: 150,
      valueGetter: ({ data }: { data: any }) => {
        if (!data) return undefined;
        return data;
      },
      flex: 2,
      cellRendererFramework: AudienceNameRenderer,
    },
    {
      colId: FEE_COL_ID,
      headerName: headerLabels.fee,
      field: FEE_COL_ID,
      sortable: false,
      width: 50,
      valueGetter: ({ data }: { data: any }) => {
        if (!data) return undefined;
        return data;
      },
      cellRendererFramework: FeeRenderer,
      cellRendererParams: {
        feeSupplyType,
        currencyOfPreference: apiData.currencyOfPreference,
        fxConversionRate: apiData.fxConversionRate,
      },
      hide: !feeSupplyType,
    },
    {
      colId: DAILY_IMPRESSIONS_COL_ID,
      headerName: headerLabels.dailyImpressions,
      field: DAILY_IMPRESSIONS_COL_ID,
      sortable: false,
      width: 70,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      valueGetter: ({ data }: { data: any }) => {
        if (!data) return undefined;
        return data;
      },
      cellRendererFramework: ForecastRenderer,
      cellRendererParams: {
        useImpressions: true,
      },
      hide: !showForecast,
    },
    {
      colId: DAILY_REACH_COL_ID,
      headerName: headerLabels.dailyReach,
      field: DAILY_REACH_COL_ID,
      sortable: false,
      width: 70,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      valueGetter: ({ data }: { data: any }) => {
        if (!data) return undefined;
        return data;
      },
      cellRendererFramework: ForecastRenderer,
      cellRendererParams: {
        useImpressions: false,
      },
      hide: !showForecast,
    },
  ] as ColDef[];
